<template>
  <h1 class="font-zeyada text-4xl my-4">Catálogos</h1>

  <div
    class="
      w-12
      h-12
      mx-auto
      my-10
      border-2 border-yellow-500
      rounded-full
      loader
    "
    v-if="!isLoaded"
  ></div>

  <TransitionRoot
    :show="isLoaded"
    enter="transition-opacity duration-500"
    enter-from="opacity-0"
    enter-to="opacity-100"
  >
    <ul
      class="
        grid grid-cols-1
        gap-x-4 gap-y-4
        md:grid-cols-2
        md:gap-x-8 md:gap-y-8
        lg:grid-cols-2
        lg:gap-x-8 lg:gap-y-8
      "
      v-if="isLoaded"
    >
      <li v-for="item in catalogs" :key="item.slug">
        <router-link
          class="
            bg-gray-800
            text-gray-300
            hover:bg-gray-700
            hover:text-white
            grid
            items-center
            px-6
            py-6
            h-52
            md:h-60
            text-lg
            rounded-md
          "
          :to="{ name: 'Catalogo', params: { slug: item.slug } }"
        >
          <font-awesome-icon
            :icon="['far', 'file-pdf']"
            class="fa-fw mx-auto"
            size="5x"
            :style="{ color: '#f49b00' }"
            aria-hidden="true"
          />
          <p class="text-center font-zeyada text-4xl">
            {{ $filters.capitalize(item.titulo) }}
          </p>
        </router-link>
      </li>
    </ul>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import { TransitionRoot } from "@headlessui/vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { API_BASE_URL } from "@/config/parameters";

library.add(faFilePdf);

export default {
  name: "Catalogos",
  components: { TransitionRoot, FontAwesomeIcon },
  setup() {
    let catalogs = ref([]);
    const isLoaded = ref(false);

    fetch(
      `${API_BASE_URL}/catalogos?_limit=1000&_start=0&_sort=updated_at:DESC&_publicationState=live`
    )
      .then((response) => response.json())
      .then((data) => {
        catalogs.value.push(...data);
        isLoaded.value = true;
      })
      .catch((error) => console.error(error));

    return {
      catalogs,
      isLoaded,
    };
  },
};
</script>
